import React, { Component } from 'react';
import './Introduction.css';
import carImage from '../../img/homepage/lean_on_car.jpg';

export class Introduction extends Component {
  render() {
    return (
      <div className="introduction-section">
        <div className="introduction-container">
          <div className="introduction-content">
            <h2 className="introduction-title">Who We Are</h2>
            <p className="introduction-text">
              MAC Formula Electric is a student-run team from 
              McMaster University working to design and build a 
              fully-electric, formula-style racecar to compete with 
              some of the best universities in North America.
            </p>
            {/* Button has been removed */}
          </div>
          
          <div className="introduction-image-container">
            <img src={carImage} alt="MAC Formula Electric Race Car" className="introduction-image" />
          </div>
        </div>
      </div>
    );
  }
}

export default Introduction;

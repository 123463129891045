import React from 'react';
import './FormulaInfo.css';
import trophyIcon from './../../img/homepage/trophy.png';
import teamIcon from './../../img/homepage/puzzle.png';
import speedIcon from './../../img/homepage/fast-response.png';
import carImage from './../../img/homepage/formula_sae.jpg';

const FormulaInfo = () => {
  return (
    <section className="formula-info-section">
      <div className="formula-info-container">
        {/* Main content area */}
        <div className="formula-info-main">
          {/* Left column - Image */}
          <div className="formula-info-image-container">
            <img 
              src={carImage} 
              alt="Formula SAE car with driver" 
              className="formula-info-image"
            />
          </div>
          
          {/* Right column - Text content */}
          <div className="formula-info-content">
            <h2 className="formula-info-title">What is Formula SAE?</h2>
            <p className="formula-info-text">
              Formula SAE challenges students across North America to build 
              a single-seat, open-wheeled racecar to compete against other 
              schools every year. There are many areas of evaluation including 
              engineering design, business and cost, and several dynamic 
              events.
            </p>
            {/* Button has been removed */}
          </div>
        </div>
        
        {/* Stats area */}
        {/* <div className="formula-stats-container">
          <div className="formula-stat-item">
            <div className="formula-stat-top">
              <img src={trophyIcon} alt="Trophy icon" className="formula-stat-icon" />
              <div className="formula-stat-value">9+</div>
            </div>
            <div className="formula-stat-label">Years of excellence</div>
          </div>
          
          <div className="formula-stat-item">
            <div className="formula-stat-top">
              <img src={teamIcon} alt="Team icon" className="formula-stat-icon" />
              <div className="formula-stat-value">180+</div>
            </div>
            <div className="formula-stat-label">Current team members</div>
          </div>
          
          <div className="formula-stat-item">
            <div className="formula-stat-top">
              <img src={speedIcon} alt="Speed icon" className="formula-stat-icon" />
              <div className="formula-stat-value">150 km/h</div>
            </div>
            <div className="formula-stat-label">Top speed</div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default FormulaInfo;
import React, { Component } from "react";

import "./SponsorExplorer.css";


// import YourLogoHere from './../../../img/sponsor-logos/your-logo-here.png';
import MacEngLogo from "./../../../img/sponsor-logos/mac-eng-logo.png";
import PetroCanadaLogo from "../../../img/sponsor-logos/Petro_canada_logo.svg";
import MESLogo from "./../../../img/sponsor-logos/mes-logo.svg";
//Platinum Sponsers
import TektronixLogo from "./../../../img/sponsor-logos/tektronix-logo.svg";
//Gold Sponsors
import EatonLogo from "../../../img/sponsor-logos/eaton_logo.png";
import FaroLogo from "../../../img/sponsor-logos/faro-logo.png";
import LemoLogo from "../../../img/sponsor-logos/LEMO.png";
import KISSoftLogo from "../../../img/sponsor-logos/kissoft-logo.png";
import PCBLLogo from "../../../img/sponsor-logos/PCBL-Logo_High.png";//pcbl
import TeslaLogo from "../../../img/sponsor-logos/tesla-logo.png";//tesla
import VintonLogo from "../../../img/sponsor-logos/vinton-logo.png";//vinton
import Comp1Logo from "../../../img/sponsor-logos/comp1-logo.png";
import DexoryLogo from "../../../img/sponsor-logos/dexory-logo.png";
import HumaneticsLogo from "../../../img/sponsor-logos/humanetics-logo.png";
import DanaLogo from "../../../img/sponsor-logos/dana-logo.png";
//Silver Sponsors
import SolidworksLogo from "./../../../img/sponsor-logos/solidworks-logo.svg";
import AltiumLogo from "./../../../img/sponsor-logos/altium-logo.png";
import AnsysLogo from "./../../../img/sponsor-logos/ansys-logo.webp";
import HaasLogo from "./../../../img/sponsor-logos/haas-logo.svg";
import MgChemLogo from "../../../img/sponsor-logos/mg-chemicals-logo.webp";
import MolexLogo from "./../../../img/sponsor-logos/molex-logo.svg";
import HakkoLogo from "./../../../img/sponsor-logos/hakko-logo.png";
import ElectroMeterLogo from "./../../../img/sponsor-logos/electro-meters-logo.png";
import ItechLogo from "./../../../img/sponsor-logos/itech-logo.png";
import Proto3000Logo from "./../../../img/sponsor-logos/proto3000-logo.png";
import LearningFactoryLogo from "./../../../img/sponsor-logos/learningfactory-logo.png";
import AirTechLogo from "../../../img/sponsor-logos/airtech-logo.png";
import JohnsonElectricLogo from "../../../img/sponsor-logos/johnsonelectric-logo.png";
import CoroplastLogo from "../../../img/sponsor-logos/coroplast-logo.png";
//Bronze Sponsors
import KvaserLogo from "./../../../img/sponsor-logos/kvaser-logo.png";
import ProtocaseLogo from "./../../../img/sponsor-logos/protocase-logo.png";
import CalspanLogo from "../../../img/sponsor-logos/calspan-logo.png";
import ADILogo from "../../../img/sponsor-logos/adi-logo.png";

export class SponsorExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: [
                {
          id: 1,
          name: "Faculty of Engineering, McMaster University",
          websiteLink: "https://www.eng.mcmaster.ca/",
          img: MacEngLogo,
          description:
            "The McMaster Faculty of Engineering is known for innovative educational programming and for its research and engagement with industry and community. Focusing on Problem-Based Learning, the Faculty builds strong foundations for undergraduate and graduate students in their professional careers.",
          color: "rgb(253, 191, 87)",
        },
        {
          id: 2,
          name: "Petro-Canada Lubricants",
          websiteLink: "https://lubricants.petro-canada.com/",
          img: PetroCanadaLogo,
          description:
            "For over 30 years, under our Petro-Canada Lubricants brand, we have researched, developed and produced world-class advanced lubricants, specialty fluids and greases. Our products continue to perform beyond expectations in virtually every industry around the globe. ",
          color: "rgb(0,0,0)",
        },

        {
          id: 3,
          name: "McMaster Engineering Society",
          websiteLink: "https://www.macengsociety.ca/",
          img: MESLogo,
          description:
            "The McMaster Engineering Society fosters the development of well rounded undergraduate engineering students through accessible support of academics, athletics, and recreational and professional activities, while maintaining the integrity and unique traditions of the faculty society.",
          color: "rgb(167, 29, 48)",
        }
      ],
      platinum: [
        {
          id: 1,
          name: "Tektronix Name",
          websiteLink: "https://www.tek.com/en",
          img: TektronixLogo,
          description: "",
        }
      ],
      gold: [
        {
          id: 1,
          name: "PCB Libraries",
          websiteLink: "https://www.pcblibraries.com/",
          img: PCBLLogo,
          description:
            "PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
          color: "rgb(0,102,179)",
        },
        {
          id: 2,
          name: "Tesla",
          websiteLink: "https://www.tesla.com/en_ca",
          img: TeslaLogo,
          description:
            "We’re building a world powered by solar energy, running on batteries and transported by electric vehicles. Explore the most recent impact of our products, people and supply chain.",
          color: "rgb(0,0,0)",
        },
        {
          id: 3,
          name: "LEMO",
          websiteLink: "https://www.lemo.com/en",
          img: LemoLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 4,
          name: "KISSoft",
          websiteLink: "https://www.kisssoft.com/en",
          img: KISSoftLogo,
          description:
            'Our software includes internationally recognized calculation standards as well as various design and optimization options, based on the experience of our customers and development engineers - according to our slogan "Sharing Knowledge".',
          color: "rgb(16, 117, 189)",
        },
        {
          id: 5,
          name: "Vinton",
          websiteLink: "https://www.vintonprecisiontool.com/",
          img: VintonLogo,
          description:
            "PCB Libraries is a lean and agile software company that offers unparalleled software for accurate, and amazingly efficient PCB library creation and management. Their goal is to offer the highest quality and most affordable PCB design solutions available, at the lowest cost possible. They have been actively involved in development of the IPC-7351 for many years.",
          color: "rgb(0,102,179)",
        },
        {
          id: 6,
          name: "Faro",
          websiteLink: "https://www.faro.com/",
          description:
            "",
          img: FaroLogo,
        },
        {
          id: 7,
          name: "Altium",
          websiteLink: "https://education.altium.com/",
          img: AltiumLogo,
          description:
            "Industry leading PCB design software that empowers users to design boards without limits—from single PCB to multi-board systems; from low frequency to high-speed.",
          color: "rgb(166,151,106)",
        },
        {
          id: 8,
          name: "Composites One",
          websiteLink: "https://www.compositesone.com/",
          description:
            "",
          img: Comp1Logo,
        },
        {
          id: 9,
          name: "Dexory",
          websiteLink: "https://www.dexory.com/",
          description:
            "",
          img: DexoryLogo,
        },
        {
          id: 10,
          name: "Humanetics",
          websiteLink: "https://www.humaneticsgroup.com/",
          description:
            "",
          img: HumaneticsLogo,
        },
        {
          id: 11,
          name: "Dana",
          websiteLink: "https://www.dana.com/",
          description:
            "",
          img: DanaLogo,
        }
      ],
      silver: [
        {
          id: 1,
          name: "Gene Haas Foundation",
          websiteLink: "https://www.haascnc.com/content/ghf/en/home.html",
          img: HaasLogo,
        },
        {
          id: 2,
          name: "Molex",
          websiteLink: "https://www.molex.com/en-us/home",
          img: MolexLogo,
        },
        {
          id: 3,
          name: "Solidworks",
          websiteLink: "https://www.solidworks.com/",
          img: SolidworksLogo,
          description:
            "SOLIDWORKS’ is a solid modelling computer-aided design (CAD) and computer-aided engineering (CAE) computer program. The program used by industry leaders provides integrated tools for every area of product development and management. Enabling enterprises to optimize processes from concepts to manufacturing.",
          color: "rgb(209,73,67)",
        },
        {
          id: 4,
          name: "Ansys",
          websiteLink: "https://www.ansys.com/",
          img: AnsysLogo,
        },
        {
          id: 5,
          name: "Eaton",
          websiteLink: "https://www.eaton.com/ca/en-gb.html",
          img: EatonLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 6,
          name: "MG Chemicals",
          websiteLink: "https://mgchemicals.com/",
          img: MgChemLogo,
          description:
            "",
          color: "rgb(0,0,0)",
        },
        {
          id: 7,
          name: "Hakko",
          websiteLink: "https://www.hakko.com/english/",
          img: HakkoLogo,
          description:
            "",
        },
        {
          id: 8,
          name: "Electro-Meters",
          websiteLink: "https://www.electro-meters.com/",
          img: ElectroMeterLogo,
          description:
            "",
        },
        {
          id: 9,
          name: "iTech",
          websiteLink: "https://www.itechate.com/",
          img: ItechLogo,
          description:
            "",
        },
        {
          id: 10,
          name: "Proto3000",
          websiteLink: "https://proto3000.com/",
          img: Proto3000Logo,
          description:
            "",
        },
        {
          id: 11,
          name: "Learning Factory",
          img: LearningFactoryLogo,
          description:
            "",
        },
        {
          id: 12,
          name: "AirTech",
          websiteLink: "https://airtechintl.com/",
          img: AirTechLogo,
          description:
            "",
        },
        {
          id: 13,
          name: "Johnson Electric",
          websiteLink: "https://www.johnsonelectric.com/",
          img: JohnsonElectricLogo,
          description:
            "",
        },
        {
          id: 14,
          name: "Coroplast",
          websiteLink: "https://www.coroplast.us/",
          img: CoroplastLogo,
          description:
            "",
        },
      ],
      bronze: [
        {
          id: 1,
          name: "Protocase",
          websiteLink: "https://www.protocase.com/",
          img: ProtocaseLogo,
        },
        {
          id: 2,
          name: "Kvaser",
          websiteLink: "http://www.kvaser.com/",
          img: KvaserLogo,
        },
        {
          id: 3,
          name: "Calspan",
          websiteLink: "https://calspan.com/",
          img: CalspanLogo,
        },
        {
          id: 4,
          name: "ADI",
          websiteLink: "https://www.analog.com/en/index.html",
          img: ADILogo,
        },
      ],
    };
  }

  render() {
    return (
      <div className="sponsor-explorer-container">
        <h1 className="sponsor-type-heading">
          <span>Official EV Partners</span>
        </h1>

        <div className="sponsor-container partner">
          {this.state.partner.map((sponsor) => (
            <>
              <a
                className="sponsor"
                href={sponsor.websiteLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="sponsors-logo"
                  src={sponsor.img}
                  alt="partner-sponsor"
                />
                <div className="sponsors-text-container">
                  <h1>{sponsor.name}</h1>
                  <h2 className="sponsor-text-description">
                    {sponsor.description}
                  </h2>
                </div>
                <div
                  className="sponsor-flag-pole"
                  style={{ background: sponsor.color }}
                ></div>
                <div
                  className="sponsor-flag"
                  style={{ background: sponsor.color }}
                >
                  <h1 className="sponsor-flag-text">Visit</h1>
                </div>
              </a>
            </>
          ))}
        </div>

        <h1 className="sponsor-type-heading">
          <span>Platinum Sponsors</span>
        </h1>
        <div className="top-sponsor-container">
          <div className="reworked-sponsor-container">
            {this.state.platinum.map((sponsor) => (
              <>
                <a
                  key={sponsor.id}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="platinum-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>

        <h1 className="sponsor-type-heading">
          <span>Gold Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.gold.map((sponsor) => (
              <>
                <a
                  key={sponsor.id}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-sponsor-id={sponsor.name.toLowerCase().replace(/\s+/g, '')}
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="gold-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>
        
        <h1 className="sponsor-type-heading">
          <span>Silver Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.silver.map((sponsor) => (
              <>
                <a
                  key={sponsor.id}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-sponsor-id={sponsor.name.toLowerCase().replace(/\s+/g, '')}
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="silver-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>

        <h1 className="sponsor-type-heading">
          <span>Bronze Sponsors</span>
        </h1>
        <div className = "top-sponsor-container">
          <div className="reworked-sponsor-container">
          {this.state.bronze.map((sponsor) => (
              <>
                <a
                  key={indexedDB}
                  className="reworked-sponsor"
                  href={sponsor.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="reworked-sponsors-logo"
                    src={sponsor.img}
                    alt="bronze-sponsor"
                  />
                </a>
              </>
            ))}
          </div>
        </div>





        
        <div className="sponsor-container silver">
          {/*temporary silver change back to bronze if there are real sponsors*/}
          
          {/* {this.state.bronze.map(sponsor => (
                 <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="bronze-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div>
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))} */}
        </div>

        {/* <h1 className="sponsor-type-heading"><span>Bronze Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.bronze.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div>

            <h1 className="sponsor-type-heading"><span>General Sponsors</span></h1>
            <div className="sponsor-container silver">
              {this.state.general.map(sponsor => (
                  <>
                  <a className="sponsor" href={sponsor.websiteLink} target="_blank" rel="noopener noreferrer">
                  <img className="sponsors-logo" src={sponsor.img} alt="platinum-sponsor"/>
                  <div className="sponsors-text-container">
                    <h1>{sponsor.name}</h1>
                    <h2 className="sponsor-text-description">
                        {sponsor.description}
                    </h2>
                  </div>   
                  /!* <div className="sponsor-flag-pole" style={{background:sponsor.color}}></div> *!/
                  <div className="sponsor-flag" style={{background:sponsor.color}}>
                      <h1 className="sponsor-flag-text">Visit
                          </h1></div> 
                  </a>  
                  </> 
              ))}
            </div> */}
      </div>
    );
  }
}

export default SponsorExplorer;

import React, { Component } from 'react'
import './HomeShowcase.css';
import PoweredBy from '../poweredby/PoweredBy';
import background from './../../img/homepage/showcase_background.jpg';

export class HomeShowcase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: true,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        }
        
        // Add resize handler for responsive adjustments
        this.handleResize = this.handleResize.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize() {
        this.setState({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        });
    }
    
    render() {
        const showcaseStyle = {
            backgroundImage: `url(${background})`,
            height: `${this.state.windowHeight}px` // Dynamically set height based on window
        };
        
        return (
            <div className="landing-ether" style={showcaseStyle}>
                <div id="landing-page-container">
                    <div className="title-container">
                        <h1 className="main-title">MAC Formula Electric</h1>
                        <h2 className="subtitle">Learning. Teamwork. Excellence.</h2>
                    </div>

                    <PoweredBy />
                </div>
            </div>
        );
    }
}

export default HomeShowcase

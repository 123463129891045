import React, { Component } from 'react'
import Header from '../header/Header';
import TeamShowcase from './teamshowcase/TeamShowcase';
import TeamMembers from './teammembers/TeamMembers';
import TeamVideo from './teamvideo/TeamVideo';
import TeamPosts from './teamposts/TeamPosts';
import PhotoSlide from '../photoslide/PhotoSlide';

import './TeamPage.css'

import openHousePhoto from './../../img/hatch/open-house.jpeg';
import Footer from '../footer/Footer';

import Button from '../button/Button.jsx';

export class TeamPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    let photoSlideLink = window.innerWidth > 600 ?
    <a href={"https://macformularacing.com/recruitment"}
    style={{ textDecoration:'none', paddingLeft:'50px'}}
    target="_blank" rel="noopener noreferrer">
    <Button text="Explore"/>
    </a> : <div/>;

    return (
      <div className="team-page-container">
        <Header />
        <TeamShowcase />
        <TeamMembers/>
        <TeamVideo />
        <TeamPosts />
        <Footer />
      </div>
    )
  }
}

export default TeamPage

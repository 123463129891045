import React, { Component } from "react";
import "./DesignContent.css";

import Accumulator_Cover from '../../../img/design_page/Accumulator_Cover.png';
import Aerodynamics_Cover from '../../../img/design_page/Aerodynamics_Cover.jpg';
import Brakes_Cover from '../../../img/design_page/Brakes_Cover.jpg';
import Business_Cover from '../../../img/design_page/Business_Cover.jpg';
import Chassis_Cover from '../../../img/design_page/Chassis_Cover.PNG';
import Composite_Cover from '../../../img/design_page/Composites_Cover.jpg';
import Controls_Cover from '../../../img/design_page/Controls_Cover.png';
import Drivetrain_Cover from '../../../img/design_page/Drivetrain_Cover.png';
import Ergonomics_Cover from '../../../img/design_page/Ergonomics_Cover.jpg';
import Machining_Cover from '../../../img/design_page/Machining_Cover.jpg';
import Suspension_Cover from '../../../img/design_page/Suspension_Cover.png';
import Low_HighVoltage_Cover from '../../../img/design_page/Low-HighVoltage_Cover.png';

import Accumulator_Image from '../../../img/design_page/Accumulator_Image.jpg';
import Aerodynamics_Image from '../../../img/design_page/Aerodynamics_Image.JPG';
import Brakes_Image from '../../../img/design_page/Brakes_Image.JPG';
import Business_Image from '../../../img/design_page/Business_Image.png';
import Chassis_Image from '../../../img/design_page/Chassis_Image.jpg';
import Composite_Image from '../../../img/design_page/Composites_Image.jpg';
import Drivetrain_Image from '../../../img/design_page/Drivetrain_Image.jpg';
import Ergonomics_Image from '../../../img/design_page/Ergonomics_Image.jpg';
import Low_HighVoltage_Image from '../../../img/design_page/Low-HighVoltage_Image.png';
import Machining_Image from '../../../img/design_page/Machining_Image.jpg';
import Suspension_Image from '../../../img/design_page/Suspension_Image.png';

export class DesignContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          height: props.height,
          scrollPosition: 0,
          activeSection: "accumulator",
        };
        this.sectionRefs = {
          accumulator: React.createRef(),
          aerodynamics: React.createRef(),
          brakes: React.createRef(),
          business: React.createRef(),
          chassis: React.createRef(),
          composites: React.createRef(),
          controls: React.createRef(),
          drivetrain: React.createRef(),
          ergonomics: React.createRef(),
          low_highvoltage: React.createRef(),
          machining: React.createRef(),
          suspension: React.createRef(),
        };
    }
    
    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({height: window.innerHeight + 'px'});
    }

    handleSectionClick = (sectionId) => {
        this.setState({ activeSection: sectionId }, () => {
            // Delay scroll action to ensure section is rendered
            setTimeout(() => {
                const sectionRef = this.sectionRefs[sectionId];
                if (sectionRef && sectionRef.current) {
                    sectionRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);  
        });
    };
 
    render() {
        const { activeSection } = this.state;

        const sections = [
        { id: "accumulator", label: "Accumulator", img: Accumulator_Cover },
        { id: "aerodynamics", label: "Aerodynamics & Cooling", img: Aerodynamics_Cover },
        { id: "brakes", label: "Brakes & Pedal Tray", img: Brakes_Cover },
        { id: "business", label: "Business", img: Business_Cover },
        { id: "chassis", label: "Chassis", img: Chassis_Cover },
        { id: "composites", label: "Composites", img: Composite_Cover },
        { id: "controls", label: "Controls", img: Controls_Cover },
        { id: "drivetrain", label: "Drivetrain", img: Drivetrain_Cover },
        { id: "ergonomics", label: "Ergonomics", img: Ergonomics_Cover },
        { id: "low_highvoltage", label: "Low-High Voltage", img: Low_HighVoltage_Cover },
        { id: "machining", label: "Machining", img: Machining_Cover },
        { id: "suspension", label: "Suspension & Steering", img: Suspension_Cover },
        ];

        const team_content = [
            {
                id: "accumulator",
                title: "Accumulator",
                img: Accumulator_Image,
                alt: "Accumulator Content",
                content: "The Accumulator Mechanical sub-team designs the enclosure for the energy storage system of the car that delivers high current to the motors during acceleration and driving. The objective of this enclosure is to protect the battery from the outside environment, allow for safe and convenient access to its electronics, while minimizing both weight and cost, and maximizing packaging efficiency. On this sub-team you’ll work closely with the electrical sub-team for safety and assembly considerations. Also, you’ll work with the chassis sub-team on its mounting position, which determines the center of mass of the car, and strategy that makes the Accumulator easy to take in and out of the car. Overall, you’ll learn electromechanical integration strategies, and how to apply them while optimizing your weight, cost and packaging efficiency."
            },
            {
                id: "aerodynamics",
                title: "Aerodynamics & Cooling",
                img: Aerodynamics_Image,
                alt: "Aerodynamics Content",
                content: "The Aerodynamics  & Cooling sub-team is responsible for the design, analysis, testing, and validation of the vehicle's aerodynamic package, which includes the front wing, rear wing, nosecone, and radiator ducts. The downforce creation and drag reduction provided by these components are integral to the vehicle's performance on the track, aiming to reduce lap times by improving handling, traction, and overall efficiency. The team collaborates with sub-teams like suspension, steering, and cooling to integrate considerations for airflow and vehicle dynamics throughout the car. Emphasis is placed on building from fundamental concepts, iterative improvement and optimization. These goals are achieved through research, analysis, mechanical design and modeling, 2D and 3D CFD, and FEA to develop an effective package."
            },
            {
                id: "brakes",
                title: "Brakes & Pedal Tray",
                img: Brakes_Image,
                alt: "Brakes Content",
                content: "The Brakes and Pedal Tray sub-team is responsible for the entire braking system, from the brake pedal assembly and master cylinders, through the brake lines, down to the brake rotors and calipers. The team also designs the accelerator pedal assembly with an adjustable pedal tray. These components are crucial to the vehicles performance as braking the car as late as possible over the minimum distance is a fundamental aspect of racing. The team works closely with the drivetrain and suspension sub-teams to ensure fitment of all components around the brake rotors; a partnership with the ergonomics team is also necessary to ensure the drivers needs are considered in the pedal assemblies. Over the last year, the sub-team has been working diligently to improve the systems performance by improving pedal ratio and investigating more thermal and structural simulations for the brake rotors."
            },
            {
                id: "business",
                title: "Business",
                img: Business_Image,
                alt: "Business Content",
                content: "The Business sub-team oversees the team’s marketing and sponsorships. Marketing tasks include the creation of social media posts to update the pages, as well as designing team merchandise. Sponsorship outreach is essential for the team, and Business sub-team members are responsible for contacting potential sponsors for competition each year. This involves working closely with other sub-teams to identify the areas that best require our attention. The Business sub-team has established connections with numerous sponsors over the past year, enabling MAC Formula Electric to function as one cohesive unit."
            },
            {
                id: "chassis",
                title: "Chassis",
                img: Chassis_Image,
                alt: "Chassis Content",
                content: "The Chassis sub-team is responsible for designing and validating the carbon fibre chassis, roll hoops, impact attenuator, firewall, and jack bar. These parts play a critical role in the functioning of the car and take up most of the manufacturing effort and time. With a strong focus on design for assembly, special consideration is given to the input from the machining and composites sub-teams to ensure that the design is feasible. Additionally, the sub-team prepares detailed engineering drawings to aid in manufacturing and ensure the parts are made to specifications."
            },
            {
                id: "composites",
                title: "Composites",
                img: Composite_Image,
                alt: "Composites Content",
                content: "The Composites sub-team works closely with Machining to complete all in-house manufacturing related tasks. Specifically, the Composites sub-team manufactures the monocoque, airfoils, nosecone, bucket seat, firewall, and all other carbon fiber, Kevlar, and fiberglass related parts. These parts must be designed and manufactured with the goal of being as lightweight as possible while not failing under their specific loading conditions. The sub-team also conducts material tests to determine the most optimal way to create composite layups and to understand how parts will fail."
            },
            {
                id: "controls",
                title: "Controls",
                img: Controls_Cover,
                alt: "Controls Content",
                content: "The Controls Sub-Team develops and designs the vehicle’s control system software, managing tasks like the vehicle startup sequence, enabling high voltage power, processing driver inputs, and monitoring signals in real-time. Our control system receives analog and digital signals from multiple ECUs on our vehicle, and also receives data through our vehicle's CAN bus. It then processes these signals and sends them back to the ECUs to perform various actions such as accelerating, closing HV contactors, and activating the brake light. During development, we often use a real-time target machine like the SpeedGoat to rapidly test and prototype our models. This machine can simulate CAN data and I/O signals allowing us to monitor outputs correspondingly. Each year our team further refines and adds new features, such as a torque vectoring algorithm, to our control system developed using MATLAB, Simulink, and StateFlow."
            },
            {
                id: "drivetrain",
                title: "Drivetrain",
                img: Drivetrain_Image,
                alt: "Drivetrain Content",
                content: "The Drivetrain sub-team is responsible for the design, validation, and assembly of the vehicle’s drivetrain subsystems. The team’s main focus are the two in-hub gearboxes found at the rear of the vehicle, which form a vital link between the motors and the wheels. Each season the team develops the housings, spindles, and even the gears, working closely with suspension and brakes to create a compact and well-integrated package. Over the past year, the team significantly reduced the size of the gearboxes and gained a deeper data-driven understanding of gear performance through an advanced simulation model. In addition to the gearboxes, the team worked closely with software and electrical members to implement a front wheel speed measurement system, which was needed for the new torque vectoring system."
            },
            {
                id: "ergonomics",
                title: "Ergonomics",
                img: Ergonomics_Image,
                alt: "Ergonomics Content",
                content: "The Human Factors and Ergonomics sub-team is responsible for all components of the car that interact with the driver. This includes a wide range of elements ranging from the steering wheel grips to all the necessary driver equipment. This sub-team must consider bodies of all shapes and sizes ranging from the 5th percentile female to 95th percentile male in every design. The sub-team designs components that must spatially fit within the cockpit and abide by all safety protocols. Additionally, the driver’s comfort, visibility, and ease of control must be maximized which results in partnerships with brakes and pedal tray, aerodynamics, suspension and steering sub-teams."
            },
            {
                id: "low_highvoltage",
                title: "Low-High Voltage",
                img: Low_HighVoltage_Image,
                alt: "Low-High Voltage Content",
                content: "The High/Low voltage sub-team specializes in designing and manufacturing in-house controllers as Printed Circuit Boards (PCB).  The most important controllers that are overseen by the Low Voltage sub-team are the Front controller and LV controller. Both boards are equipped with STM32 microcontrollers which are responsible for the vehicles controls algorithm, startup sequences, sensor inputs, safety systems, and communication with other vehicle components, ensuring safe and efficient operation in compliance with Formula SAE regulations. The High voltage sub-team is responsible for controllers such as HV DCDC, HV controller, thermal monitoring system, and the charger board. The high voltage boards in the vehicle are responsible for managing and converting power from the high-voltage battery pack to supply the vehicle's various systems. They ensure efficient power distribution, control critical safety functions, and enable the proper operation of the vehicle's electrical systems. Our team designs these boards in Altium Designer, and every year these boards are modified to add additional features and fixes to our vehicle. Manufacturing and testing these boards are a tedious process requiring a lot of precise soldering and debugging!"
            },
            {
                id: "machining",
                title: "Machining",
                img: Machining_Image,
                alt: "Machining Content",
                content: "The Manufacturing sub-team is composed of both Machining and Composites sub-teams that work closely together to produce in-house parts. The Machining sub-team is responsible for manufacturing test rigs and other metal components. These parts are produced using a variety of machining processes such as waterjet cutting, lathing, milling, and welding, with the goal of achieving precise tolerances. The sub-team also emphasizes learning to draft and interpret engineering drawings to ensure parts meet specifications while developing practical machining skills."
            },
            {
                id: "suspension",
                title: "Suspension & Steering",
                img: Suspension_Image,
                alt: "Suspension Content",
                content: "The Steering and Suspension team is responsible for the design, manufacture, testing, and validation of functional steering and suspension systems for Mac Formula cars. We create vehicle dynamics models of our cars and analyze them using vehicle dynamics first-principles to generate objectives for the handling behaviour we want our car to exhibit in various racing scenarios, such as acceleration, braking, low and high speed cornering, as well as combinations of these scenarios. From these models, we then design the parts that will give us our desired handling, such as uprights, wishbones, bellcranks, and steering system, while also ensuring that they exceed our expected load cases. Last year we incorporated a Z-bar anti-roll bar setup into our suspension package to mitigate roll in corners and allow us to use softer springs, as well as redesigned our wishbones and uprights, in order to better achieve our handling goals. Our team is truly a multi-disciplinary one, with our members working on projects that incorporate vehicle dynamics, mechanical design, dynamic simulations, FEA analysis, manufacturing, and more."
            }
        ]

        return (
            <div>
                {/*Section 2*/}

                <div className="row1-cover-container">
                    {sections.map((section) => (
                        <div
                            key={section.id}
                            className={`team-item ${activeSection === section.id ? "active" : ""}`}
                            onClick={() => this.handleSectionClick(section.id)}
                        >
                            <img src={section.img} className="team-cover" alt={`${section.label} Cover`} />
                            <div className="team-text-cover">{section.label}</div>
                        </div>
                    ))}
                </div>

                {/*Section 3*/}

                {/* Circles Section */}
                <div className="info-cover-container">
                    {sections.map((section) => (
                        <div
                            key={section.id}
                            ref={this.sectionRefs[section.id]}
                            className={`info-item ${activeSection === section.id ? "active" : ""}`}
                            onClick={() => this.handleSectionClick(section.id)}
                        >
                            <img src={section.img} className="info-cover" alt={`${section.label} Cover`} />
                            <div className="info-text-cover">{section.label}</div>
                        </div>
                    ))}
                </div>
                

                {/* Content Section */}

                <div className="info-content">
                    {team_content.map((team) => (
                        <div 
                            key={team.id}
                            className={`section-content ${activeSection === team.id ? "active" : ""}`}
                        >
                            <h2>{team.title}</h2>
                            <div className="info-content-wrapper">
                                <img src={team.img} alt={team.alt} className={`${team.id}-content-image`} />

                                <p>{team.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default DesignContent
import React, { Component } from 'react'
import  './HomePage.css';
import HomeShowcase from '../homeshowcase/HomeShowcase';
import Header from '../header/Header';
import ShowcaseLink from '../showcaselink/ShowcaseLink';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import Introduction from '../introduction/Introduction.jsx';
import Countdown from '../countdown/Countdown';
import FormulaInfo from '../formulainfo/FormulaInfo';
import MeetTeam from '../meetteam/MeetTeam';
import WorkWithUs from '../workwithus/WorkWithUs';

import amkBackground from './../../img/parts/amk-close.png';
import playButton from './../../img/playButton.svg'
import Footer from '../footer/Footer';

import darkToLoad from './../../img/renders/showcase-red.png';
import lightToLoad from './../../img/renders/showcase-light.png';


export class HomePage extends Component {
    constructor (props){
        super(props)

        this.state = {
            darkLoaded: false,
            lightLoaded: false,
            entered: false,
            header:<div />,
            pastedShowcase: true
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    lightLoaded = () => {
        this.setState({lightLoaded:true})
    }

    darkLoaded = () => {
        this.setState({darkLoaded:true});
    }

    DidEnter =  () => {
        console.log("yrd")
        this.setState({
            entered: true,
            header:<Header />
        })
    }

    didPassShowcase = () => {
        this.setState({
            pastedShowcase: true
        })
    }

    

    render() {
            
            if(this.state.pastedShowcase)
                {  
                  //Show Elements
                  
                    setTimeout(
                        function(){
                            try{
                  document.getElementById("about-section-container").style.opacity=1;
                } catch{}
                        },800);
                
              }


            if(!this.state.darkLoaded || !this.state.lightLoaded){
                return( 
                    <div className="loading-page">
                        <LoadingSpinner />
                        <img src={darkToLoad} style={{opacity:0}} alt="" onLoad={this.darkLoaded}/>
                        <img src={lightToLoad} style={{opacity:0}} alt="" onLoad={this.lightLoaded}/>
                    </div>
                )
            }
            return (
                <div className="home-page-component">
                    <Header />
                    <HomeShowcase />
                    <Introduction/>
                    <Countdown />
                    <FormulaInfo />
                    <MeetTeam />
                    <WorkWithUs />
                    <ShowcaseLink 
                        backgroundImage = {amkBackground}
                        type = 'Video'
                        title = 'About Us'
                        linkContainer =           
                            {<a href="https://www.youtube.com/watch?v=M89uLc4ShOY&t=1s" target="_blank" rel="noopener noreferrer">
                                <img className="play-button" src={playButton} alt=""/>
                            </a>}
                    />
                    <Footer />
                </div>
            )
        
    }
}

export default HomePage

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo from './../../img/team-logos/logo-transparent.png';
import './Footer.css'

export class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section">
            <img src={logo} alt="MAC Formula Electric Logo" className="footer-logo" />
            <div className="company-description">
              <h2>Company</h2>
              <p>We are a team of McMaster University students working to design and build a fully-electric, formula-style race car. Combining cutting-edge engineering and design we compete every year on the North American stage in Michigan.</p>
            </div>
          </div>
          
          <div className="footer-section">
            <h2>About Us</h2>
            <ul>
              <li><Link to="/design">Design</Link></li>
              <li><Link to="/team">Team</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
            </ul>
          </div>

          <div className="footer-section">
            <h2>Help</h2>
            <ul>
              <li><a href="https://alumni.mcmaster.ca/s/1439/ifundmac/project.aspx?sid=1439&gid=1&pgid=12563&cid=22502&ecid=22502&crid=0&calpgid=3304&calcid=5363" target="_blank" rel="noopener noreferrer">Donate</a></li>
              <li><Link to="/recruitment">Join Us</Link></li>
              <li><Link to="/sponsors">Sponsors</Link></li>
            </ul>
          </div>

          <div className="footer-section">
            <h2>Connect</h2>
            <div className="social-icons">
              <a href="https://www.instagram.com/macformulaelectric" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2xl" className="social-icon" />
              </a>
              <a href="https://www.linkedin.com/company/27013195" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2xl" className="social-icon" />
              </a>
              {/*<a href="https://www.facebook.com/MACFormulaElectric/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2xl" className="social-icon" />
              </a>
              <a href="https://www.youtube.com/@macformulaelectric" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2xl" className="social-icon" />
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer

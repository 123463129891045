import React, { Component } from 'react'

import './DesignPage.css';
import Header from '../header/Header';
import DesignLanding from './designlanding/DesignLanding';
import DesignContent from './designcontent/DesignContent';

import Footer from '../footer/Footer';

export class DesignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      scrollPosition: 0,
      activeSection: "accumulator",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({height: window.innerHeight + 'px'});
  }

  render() {

    return (
      <div className="cars-container">
        <Header />
        <DesignLanding />
        <DesignContent />
        <Footer />
      </div>
    )
  }
}

export default DesignPage

import React, { Component } from 'react';
import './WorkWithUs.css';
import { Link } from 'react-router-dom';
import sponsorsImage from '../../img/homepage/workwithus.jpg';

export class WorkWithUs extends Component {
  render() {
    return (
      <div className="work-with-us-section">
        <div className="work-with-us-container">
          <div className="work-with-us-image-container">
            <img src={sponsorsImage} alt="Formula car with sponsor logos" className="work-with-us-image" />
          </div>
          
          <div className="work-with-us-content">
            <h2 className="work-with-us-title">Work With Us</h2>
            <p className="work-with-us-text">
              Whether you want to join the team, partner with us as
              a sponsor, or lend your expertise, we're always
              looking to collaborate. Be part of something exciting:
              help us innovate and compete!
            </p>
            <div className="work-with-us-button-container">
              <Link to="/sponsors" className="work-with-us-button">
                View Sponsors & Opportunities
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkWithUs; 
import React, { useState, useEffect } from 'react';
import './Countdown.css';
// Removed brush stroke import

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    // Move targetDate inside useEffect to avoid recreating it on every render
    const targetDate = new Date('2025-06-16T08:00:00'); // Example: June 14, 2025
    
    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;
      
      if (difference <= 0) {
        // If we've reached the target date
        clearInterval(intervalId);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }
      
      // Calculate remaining time
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);
    
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array since targetDate is now local to the effect

  return (
    <div className="countdown-section">
      <div className="countdown-container">
        <h2 className="countdown-title">Days Until We Hit The Track!</h2>
        <div className="countdown-title-underline">
          {/* Replaced image with a black line (using CSS) */}
          <div className="black-line"></div>
        </div>
        
        <div className="countdown-timer">
          <div className="countdown-unit">
            <div className="countdown-box-shadow"></div>
            <div className="countdown-box">
              <div className="countdown-value">{timeLeft.days}</div>
            </div>
            <div className="countdown-label">DAYS</div>
          </div>
          
          <div className="countdown-separator">
            <div className="countdown-dot"></div>
            <div className="countdown-dot"></div>
          </div>
          
          <div className="countdown-unit">
            <div className="countdown-box-shadow"></div>
            <div className="countdown-box">
              <div className="countdown-value">{timeLeft.hours}</div>
            </div>
            <div className="countdown-label">HOURS</div>
          </div>
          
          <div className="countdown-separator">
            <div className="countdown-dot"></div>
            <div className="countdown-dot"></div>
          </div>
          
          <div className="countdown-unit">
            <div className="countdown-box-shadow"></div>
            <div className="countdown-box">
              <div className="countdown-value">{timeLeft.minutes}</div>
            </div>
            <div className="countdown-label">MINUTES</div>
          </div>
          
          <div className="countdown-separator">
            <div className="countdown-dot"></div>
            <div className="countdown-dot"></div>
          </div>
          
          <div className="countdown-unit">
            <div className="countdown-box-shadow"></div>
            <div className="countdown-box">
              <div className="countdown-value">{timeLeft.seconds}</div>
            </div>
            <div className="countdown-label">SECONDS</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
import React, { Component } from "react";
import './TeamPosts.css';

import Post1 from './../../../img/team-posts/post-1.png';
import Post2 from './../../../img/team-posts/post-2.png';
import Post3 from './../../../img/team-posts/post-3.png';

export class TeamPosts extends Component {
    render() {
        return (
            <div className="posts-container">
                <a className="instagram-account" href="https://www.instagram.com/macformulaelectric" target="_blank" rel="noopener noreferrer"><h1>@macformulaelectric</h1></a>

                <div className="instagram-posts">
                    <a href="https://www.instagram.com/p/C9vtNyouiZY/?hl=en&img_index=1" target="_blank" rel="noopener noreferrer"> <img src={Post1} alt="Gold Sponsors" /></a>

                    <a href="https://www.instagram.com/p/C95NWbjO_-V/?hl=en&img_index=1" target="_blank" rel="noopener noreferrer"> <img src={Post2} alt="Silver Sponsors" /> </a>  

                    <a href="https://www.instagram.com/p/C-AjIOIuG4S/?hl=en&img_index=1" target="_blank" rel="noopener noreferrer"> <img src={Post3} alt="Bronze Sponsors" /> </a>
                </div>
            </div>
        ) 
    }
}

export default TeamPosts;
import React, { Component } from 'react';
import './DesignLanding.css';

import MainCar_Map from '../../../img/design_page/MainCar_Map.png';
import Car_With_Text from '../../../img/design_page/CarMap-WithText.png';

export class DesignLanding extends Component {
    render() {
        return (
            <div className='main-car-map-container'>
                <img src = {Car_With_Text} className="main-car-map" alt=""/>
                {/*<div className='car-teams aerodynamics-text' >Aerodynamics</div>
                <div className='car-teams suspension-text'>Suspension & Steering</div>
                <div className='car-teams controls-text'>Controls</div>
                <div className='car-teams drivetrain-text'>Drivetrain</div>
                <div className='car-teams brakes-text'>Brakes and Pedal Tray</div>
                <div className='car-teams chassis-text'>Chassis</div>
                <div className='car-teams composite-text'>Composite</div>
                <div className='car-teams voltage-text'>Low-High Voltage</div>
                <div className='car-teams accumulator-text'>Accumulator</div>
                <div className='car-teams ergonomics-text'>Ergonomics</div>
                <div className='car-teams machining-text'>Machining</div>*/}
            </div>
        )
    }
}

export default DesignLanding
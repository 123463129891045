import React, { Component } from 'react';
import './MeetTeam.css';
import { Link } from 'react-router-dom';
import teamImage from '../../img/homepage/meet-the-team.jpg'; 

export class MeetTeam extends Component {
  render() {
    return (
      <div className="meet-team-section">
        <div className="meet-team-container">
          <div className="meet-team-content">
            <h2 className="meet-team-title">Meet The Team</h2>
            <p className="meet-team-text">
              Behind every bolt, circuit, and strategy is a
              team of McMaster students who love what they
              do. From engineering to business, we work
              together to design, build, and compete with an
              electric race car that represents months of hard
              work, late nights, and a shared drive to
              succeed.
            </p>
            <div className="meet-team-button-container">
              <Link to="/team" className="meet-team-button">
                Learn more
              </Link>
            </div>
          </div>
          
          <div className="meet-team-image-container">
            <img src={teamImage} alt="MAC Formula Electric Team Members" className="meet-team-image" />
          </div>
        </div>
      </div>
    );
  }
}

export default MeetTeam; 
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
import logo from './../../img/team-logos/logo-transparent.png';

class Header extends Component{

    pageIsSelected(currentPage, checkPage)
    {
        return currentPage === checkPage ? "active" : "";
    }

    componentDidMount () {
        (document.getElementById("header-container")).style.opacity=1;
    }
    

    render() {

        var headerClass = "static-header";

        var prevScrollpos = window.pageYOffset;
        


        if( document.body.offsetWidth>800){


            headerClass = "header"
            window.onscroll = function() {



                try{
                var currentScrollPos = window.pageYOffset;

                if(window.pageYOffset > 90){
                    (document.getElementById("header")).style.backgroundColor="rgba(0,0,0,1)";
                } else {
                    (document.getElementById("header")).style.backgroundColor="rgba(0,0,0,0)";
                }


                if(prevScrollpos < (currentScrollPos +1) )
                {
                    console.log('hi');
                    if(currentScrollPos > 90)
                    {
                        (document.getElementById("header")).style.top="-90px";
                        
                    }
                    else{
                        
                    }
                }
                else{
                    (document.getElementById("header")).style.top="0" ;
                }





            }catch{}
            prevScrollpos = currentScrollPos;



            } 
        }
        if(document.body.offsetWidth < 800) {
            
            window.onscroll = function() {



                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos < (currentScrollPos +1) ) {
                    document.getElementById("toggle").checked = false;
                    if(currentScrollPos > 90)
                    {
                        (document.getElementById("header-container")).style.top="-90px";
                    }
                    else{
                        (document.getElementById("header-container")).style.top="-".concat(currentScrollPos,"px");
                    }
                } else {
                    (document.getElementById("header-container")).style.top="0" ;
                }
                prevScrollpos = currentScrollPos;
            }
        }

        return (
            <div>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet"/>            
            <div id="header-container">
            <div className="tracker-backgound">
                <div id={`${headerClass}`}>
                    <div className="logo">
                    <Link className="logo-image" to="/">
                        <img src={logo} className="logo-image" alt=""></img>
                    </Link>
                    <Link className="logo-text" to="/">MAC Formula Electric</Link>
                    </div>

                    <input type="checkbox" id="toggle"></input>
                    <div className="hamburger"><div>
                    </div></div>
                    <ul className="nav-bar">    
                        {window.innerWidth < 800 ? 
                            <li className={`nav-bar-item ${window.location.pathname === "/" ? "active" : ""}`}>
                                <Link className="nav-link" to="/">Home</Link>
                            </li> 
                            : <div/>}
                        <li className={`nav-bar-item ${window.location.pathname === "/design" ? "active" : ""}`}>
                             <Link className="nav-link" to="/design">Design</Link>
                        </li> 
                        <li className={`nav-bar-item ${window.location.pathname === "/team" ? "active" : ""}`}>
                            <Link className="nav-link" to="/team">Team</Link>
                        </li>
                        <li className={`nav-bar-item ${window.location.pathname === "/recruitment" ? "active" : ""}`}>
                            <Link className="nav-link" to="/recruitment">Recruitment</Link>
                        </li>   
                        <li className={`nav-bar-item ${window.location.pathname === "/sponsors" ? "active" : ""}`}>
                            <Link className="nav-link" to="/sponsors">Sponsors</Link>
                        </li> 
                        <li className={`nav-bar-item ${window.location.pathname === "/contact" ? "active" : ""}`}>
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                        <li className="nav-bar-item nav-bar-item-donate">
                            <a className="nav-link" target='_blank' href="https://alumni.mcmaster.ca/s/1439/ifundmac/project.aspx?sid=1439&gid=1&pgid=12563&cid=22502&ecid=22502&crid=0&calpgid=3304&calcid=5363">Donate</a>
                        </li>
                    </ul>
                </div>
                

                </div>
            </div>
            {document.body.offsetWidth > 800 ? <div /> : <div className="top-buffer"></div>}
            </div>
        );
    }
}



export default Header;
import React, { Component } from 'react';
import './TeamShowcase.css';

import teamPhoto from './../../../img/team/TeamPhoto.jpg';

export class TeamShowcase extends Component {
    constructor(props) {
        super(props);
        // Add a resize handler
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.handleResize();
        }, 500);
        
        // Add resize listener to update layout when screen size changes
        window.addEventListener('resize', this.handleResize);
    }
    
    componentWillUnmount() {
        // Clean up listener
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize() {
        const showcase = document.getElementById('team-showcase-container');
        const image = document.getElementById('team-showcase-image');
        const textContainer = document.getElementById('team-showcase-text-container');
        const titleContainer = document.querySelector('.team-showcase-title-container');
        
        if (showcase && image && textContainer) {
            // Set opacity for the container
            showcase.style.opacity = 1;
            
            // Handle different screen sizes
            if (window.innerWidth <= 768) { // Mobile breakpoint
                // For mobile, ensure proper centering
                image.style.left = '0';
                image.style.transform = 'scale(1)'; // Remove scaling on mobile
                
                // Critical iPhone centering fix
                textContainer.style.position = 'absolute';
                textContainer.style.width = window.innerWidth <= 428 ? '90%' : '100%';
                textContainer.style.left = '50%';
                textContainer.style.transform = 'translateX(-50%)';
                textContainer.style.top = '15%';
                textContainer.style.bottom = 'auto';
                
                // Ensure text itself is centered
                if (titleContainer) {
                    titleContainer.style.width = '100%';
                    titleContainer.style.textAlign = 'center';
                }
                
            } else if (window.innerWidth <= 1200) {
                // Tablet/medium size
                image.style.left = '0';
                image.style.transform = 'scale(1.1)';
                textContainer.style.position = 'absolute';
                textContainer.style.top = '15%';
                textContainer.style.bottom = 'auto';
                textContainer.style.left = '50%';
                textContainer.style.transform = 'translateX(-50%)';
            } else {
                // Desktop - FIX: Use absolute positioning instead of relative
                image.style.left = '0';
                textContainer.style.position = 'absolute'; // Changed from relative
                textContainer.style.top = '15%';
                textContainer.style.bottom = 'auto';
                textContainer.style.left = '50%';
                textContainer.style.transform = 'translateX(-50%)'; // Add translation to center
            }
        }
    }
    
    render() {
        return (
            <div id="team-showcase-container">
                <img id="team-showcase-image" src={teamPhoto} alt="MAC Formula Electric Team" />
                <div className="team-showcase-gradient"/>
                <div id="team-showcase-text-container">
                    <div className="team-showcase-title-container">
                        <h1 className="team-showcase-title">Meet the Team</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamShowcase;

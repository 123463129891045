import React, { Component } from 'react'
import './PoweredBy.scss';

import geneHaasFoundationLogo from './../../img/sponsor-logos/genehaasfoundation_logo.png';
import mcmasterLogo from './../../img/sponsor-logos/mcmastersquare.png';
import solidworksLogo from './../../img/sponsor-logos/solidworks-logo.svg';
import altiumLogo from './../../img/sponsor-logos/altium-vector-logo.png';


let sponsors = [
  {logo:mcmasterLogo,
    link:"https://www.eng.mcmaster.ca/",
    color:"rgb(94,22,51)",
    id:1},
  {logo:geneHaasFoundationLogo,
    link:"https://www.haascnc.com/index.html",
    color:"rgb(46,86,153)",
    id:2},
  {logo:altiumLogo,
    link:"https://www.altium.com/",
    color:"rgb(166,151,106)",
    id:3},
  {logo:solidworksLogo,
    link:"https://www.solidworks.com/",
    color:"rgb(209,73,67)",
    id:4},
]


export class PoweredBy extends Component {
  componentDidMount() {
    // Show the component as soon as it mounts
    setTimeout(() => {
      const container = document.getElementById("powered-by-container");
      if (container) {
        container.style.opacity = 1;
      }
      
      // Make all sponsor logos visible
      for (let i = 1; i <= sponsors.length; i++) {
        setTimeout(() => {
          const sponsor = document.getElementById(`powered-by-sponsor-${i}`);
          if (sponsor) {
            sponsor.style.opacity = '1';
          }
        }, 300 * i);
      }
    }, 500);
  }

  render() {
    return (
      <div id="powered-by-container">
        <div className="powered-by-title-container">
          <h1 className="powered-by-title">
            Powered By
          </h1>
        </div>
        
        <div className="powered-by-sponsors-container">
          {sponsors.map(sponsor => (
            <a 
              id={`powered-by-sponsor-${sponsor.id}`} 
              href={sponsor.link} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{borderColor:sponsor.color}}
              className="sponsor-logo-wrapper"
            >
              <img className="powered-by-sponsor-image" src={sponsor.logo} alt=""/>
            </a>
          ))}
        </div>
      </div>
    )
  }
}

export default PoweredBy

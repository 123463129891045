import React, {
  Component
} from 'react'

import './TeamMembers.css'

import JoshuaPic from './../../../img/team-members/joshua_dorland.jpg';
import BlakePic from './../../../img/team-members/blake_freer.jpg'
import CrystalPic from './../../../img/team-members/crystal_lin.jpg'
import AriannaPic from './../../../img/team-members/arianna_ramotar.jpg';
import HimanshuPic from './../../../img/team-members/himanshu_singh.jpg';
import LucasPic from './../../../img/team-members/lucas_haber.jpg';
import SimonPic from './../../../img/team-members/simon_armbruster.jpg';
import IoannisPic from './../../../img/team-members/ioannis_papaspyridis.jpg';
import CarterPic from './../../../img/team-members/carter_fenton.jpg';
import ChloePic from './../../../img/team-members/chloe_castellino.jpg';
import ZacharyPic from './../../../img/team-members/zachary_krbavac.jpg';
import RyanPic from './../../../img/team-members/ryan_torok.jpg';
import NathanPic from './../../../img/team-members/nathan_sheogobind.jpg';
import AdaPic from './../../../img/team-members/ada_wu.jpg';
import KaylaPic from './../../../img/team-members/kayla_siriban.jpg';
import ChrishaPic from './../../../img/team-members/chrisha_josephanton.jpg';
import LucaPic from './../../../img/team-members/luca_panziera.jpg';
import RahimPic from './../../../img/team-members/rahim_aziz1.jpg';
import KartikeyaPic from './../../../img/team-members/kartikeya_babhuta.jpg';
import noPic from './../../../img/team-logos/black-background-logo.png';


export class TeamMembers extends Component {
  constructor() {
    super()
    this.state = {
      members: [{
          name: 'Arianna Ramotar',
          photo: AriannaPic,
          position: 'Team Principal',
          linkedin: 'https://www.linkedin.com/in/ariannaramotar/'
        },
        {
          name: 'Joshua Dorland',
          photo: JoshuaPic,
          position: 'Electrical Technical Director',
          linkedin: 'https://www.linkedin.com/in/joshdorland/'
        },
        {
          name: 'Luca Panziera',
          photo: LucaPic,
          position: 'Mechanical Technical Director',
          linkedin: 'https://www.linkedin.com/in/luca-panziera/'
        },  
        {
          name: 'Crystal Lin',
          photo: CrystalPic,
          position: 'Business Lead',
          linkedin: "https://www.linkedin.com/in/crystal-lin4/"
        },
        {
          name: 'Himanshu Singh',
          photo: HimanshuPic,
          position: 'LV Electronics Lead',
          linkedin: "https://www.linkedin.com/in/himanshu-singh-99470b207/"
        },
        {
          name: 'Rahim Aziz',
          photo: RahimPic,
          position: 'HV Electronics Lead',
          linkedin: "https://www.linkedin.com/in/rahim-aziz/"
        },
        {
          name: 'Lucas Haber',
          photo: LucasPic,
          position: 'Wire Harness Lead',
          linkedin: "https://www.linkedin.com/in/lucas--haber/"
        },
        {
          name: 'Nathan Sheogobind',
          photo: NathanPic,
          position: 'Suspension & Steering Lead',
          linkedin: "https://www.linkedin.com/in/nathan-sheogobind/"
        },
        {
          name: 'Ada Wu',
          photo: AdaPic,
          position: 'Manufacturing (Machining) Lead',
          linkedin: "https://www.linkedin.com/in/ada-wu-38a708279/"
        },
        {
          name: 'Simon Armbruster',
          photo: SimonPic,
          position: 'Manufacturing (Composites) Lead',
          linkedin: "https://www.linkedin.com/in/simon-armbruster-a421b2261/"
        },
        {
          name: 'Ryan Torok',
          photo: RyanPic,
          position: 'Brakes & Tires Lead',
          linkedin: "https://www.linkedin.com/in/ryan-torok-894623253/"
        },
        {
          name: 'Carter Fenton',
          photo: CarterPic,
          position: 'Chassis Lead',
          linkedin: "https://www.linkedin.com/in/carter-g-fenton/"
        },
        {
          name: 'Chrisha Joseph-Anton',
          photo: ChrishaPic,
          position: 'Human Factors & Ergonomics Lead',
          linkedin: "https://www.linkedin.com/in/chrisha-joseph-anton/"
        },
        {
          name: 'Chloe Castellino',
          photo: ChloePic,
          position: 'Aerodynamics and Cooling Lead',
          linkedin: "https://www.linkedin.com/in/chloecastellino/"
        },
        {
          name: 'Zachary Krbavac',
          photo: ZacharyPic,
          position: 'Drivetrain Lead',
          linkedin: "https://www.linkedin.com/in/zachary-a-krbavac/"
        },
        {
          name: 'Blake Freer',
          photo: BlakePic,
          position: 'Software Lead',
          linkedin: "https://www.linkedin.com/in/blake-freer/"
        },
        {
          name: 'Kartikeya Babhuta',
          photo: KartikeyaPic,
          position: 'Battery Integration Lead',
          linkedin: "https://www.linkedin.com/in/kbabhuta/"
        },
        {
          name: 'Kayla Siriban',
          photo: KaylaPic,
          position: 'Accumulator Mechanical Lead',
          linkedin: "https://www.linkedin.com/in/kaylasiriban/"
        },
        {
          name: 'Ioannis Papaspyridis',
          photo: IoannisPic,
          position: 'Motor Drive Lead',
          linkedin: "https://www.linkedin.com/in/ipapaspyridis/"
        },
      ]
    }
  }


  render() {
    return ( 
      <div className = "" >
        <h1 className = "team-showcase-text" > TEAM LEADERSHIP </h1> 
      <div className = "team-members-container" >
      {
        this.state.members.map(member => ( 
          <div className = "team-member-container" >
            <div className = "team-member-image-container" >
              <img className = "team-member-image" src = {member.photo} alt = "" />
            </div> 
            <div className = "team-member-text-container" >
              <h1 className = "team-member-name" > {member.name} </h1> 
              <h2 className = "team-member-position" > {member.position} </h2> 
              <a href = {member.linkedin} style = {{textDecoration: 'none'}} target = "_blank" rel = "noopener noreferrer" >
                <div className = "team-member-linkedin-button" >
                  <h1 className = "team-member-linkedin-name" > in </h1> 
                </div> 
              </a> 
            </div> 
          </div>
        ))
      } 
      </div> 
      </div>
    )
  }
}

export default TeamMembers;

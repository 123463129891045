import React, { Component } from "react";
import './TeamVideo.css';

import DITLVideo from './../../../img/team-video/DITL-video3.mp4';

export class TeamVideo extends Component {
    
    constructor(props) {
        super(props);
        this.videoRef = React.createRef(); // Create a ref for the video element
        this.videoObserver = null;
    }

    componentDidMount() {
        // Create an IntersectionObserver to detect when the video is in view
        const handleIntersection = (entries) => {
            // Pause or play the video based on whether it is in view
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (this.videoRef.current) {
                        this.videoRef.current.play();
                    }
                } else {
                    if (this.videoRef.current) {
                        this.videoRef.current.pause();
                    }
                }
            });
        };
  
        const options = { threshold: 0.5 }; // Plays the video when 50% of it is in view

        const videoObserver = new IntersectionObserver(handleIntersection, options); // Create the observer and start observing the video element

        // Start observing the video element
        if (this.videoRef.current) {
            videoObserver.observe(this.videoRef.current);
        }
    }

    componentWillUnmount() {
        if (this.videoObserver) {
            this.videoObserver.disconnect();  // Clean up when unmounting
        }
    }

    render() {
        return (
            <div className="video-container">
                {/* Text */}
                <h1 className="video-text">Check out a day in the life of some of our leads!</h1>
                
                {/* Phone frame with video */}
                <div className="phone-frame-container">
                    <video 
                        ref={this.videoRef} 
                        className="phone-video" 
                        controls
                        playsInline
                        muted={true}
                    >
                        <source src={DITLVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        )
    }
}

export default TeamVideo;